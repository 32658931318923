import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Here is how IDE plug-ins invoke CodeStory from the command-line.`}</p>
    <h4 {...{
      "id": "mac-os-x-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#mac-os-x-example",
        "aria-label": "mac os x example permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Mac OS X example`}</h4>
    <p>{`CodeStory will find out what project that file belongs to, open it and open a search results window:`}</p>
    <pre style={{
      whiteSpace: 'pre-line'
    }}>/Applications/Code\ Story.app/Contents/MacOS/Code\ Story -f /Users/dom/ACME/index.js -t ABCDE</pre>
    <p>{`If you don't want to specify a file, you can use the path to any repository wired up to the project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      